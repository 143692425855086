<script>
    import Navbar from "../components/Navbar.svelte";
    import {apiGetStudentInfo, logout} from "../utils/api.ts";
    import MIcon from "../components/MIcon.svelte";
    import {APP_VERSION} from "../config.js";
    import UpdateMeButton from "../components/UpdateMeButton.svelte";
    import ThemePicker from "../components/ThemePicker.svelte";
    import MySubgroupModal from "../components/MySubgroupModal.svelte";
    import {preferences} from "../stores.ts";
    import {_icon_literal} from "../utils/icon.js";
    import {_} from "svelte-i18n";
    // import tg from "../res/telegram.svg";
    import Telegram from "../res/Telegram.svelte";

    const __unused = _icon_literal("check")

    let ip;
    let error = null;
    let submitting = false;

    async function submit() {
        submitting = true;
        try {
            // await set_settings(ip);
        } catch (err) {
            error = err;
        } finally {
            submitting = false;
        }
    }

    const dataPromise = apiGetStudentInfo();
    let clientInfo = {};
    clientInfo[$_('page.info.clientInfo.version', {default: 'App version'})]= APP_VERSION;
    clientInfo[$_('page.info.clientInfo.platform', {default: 'Platform'})]= navigator.platform;
    clientInfo[$_('page.info.clientInfo.language', {default: 'Language'})]= navigator.language;
    clientInfo[$_('page.info.clientInfo.width', {default: 'Screen width'})]= window.innerWidth;
    clientInfo[$_('page.info.clientInfo.height', {default: 'Screen height'})]= window.innerHeight;

</script>

<Navbar pageName={$_('page.settings.nav', {default: 'Settings'})} canReturn={true}>
    <ThemePicker/>
    <MySubgroupModal/>
    <article class="round">
        <div class="field">
            <nav class="no-padding">
                <div class="max">
                    <h6>{$_('page.settings.autoScroll', {default: 'Auto scroll today'})}</h6>
                    <div>{$_('page.settings.autoScrollDescription', {default: 'Scroll to today automatically'})}</div>
                </div>
                <label class="switch">
                    <input type="checkbox" bind:checked="{$preferences.preferAutoScrollToToday}">
                    <span></span>
                </label>
            </nav>
        </div>
        <div class="field">
            <nav class="no-padding" data-ui="#my-subgroup">
                <div class="max">
                    <h6>{$_('page.settings.mySubgroups', {default: 'My subroups'})}</h6>
                    <div>{$_('page.settings.mySubgroupsDescription', {default: 'Filter schedule for your subgroup'})}</div>
                </div>
                <button>
                    <MIcon>edit</MIcon>
                    {$_('page.settings.mySubgroupsChange', {default: 'Change'})}
                </button>
            </nav>
        </div>
    </article>
</Navbar>