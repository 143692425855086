<script>
    import Navbar from "../components/Navbar.svelte";
    import MIcon from "../components/MIcon.svelte";
    import {discoveredSubgroups, preferences, scheduleCache, subgroupFilter} from "../stores.ts";
    import {apiGetSchedule} from "../utils/api.ts";
    import {_icon_literal} from "../utils/icon.js";
    import {convertDateReadable, convertTimeReadable, isCurrentDay} from "../utils/dates.js";
    import {_} from "svelte-i18n";
    import {get} from "svelte/store";
    import { onDestroy, onMount } from "svelte";

    let error, loading;
    let startOfWeek, endOfWeek;
    let weekOffset = 0;
    let today = new Date();

    const iconMap = {
        'Лабораторные': _icon_literal("science"),
        'Лекции': _icon_literal("headset_mic"),
        'Практические': _icon_literal("construction"),
        'Курсовая работа': _icon_literal("book"),
        'Зачет': _icon_literal("edit_document"),
        'Балльно-рейтинговый контроль': _icon_literal("edit")
    };

    let scrolledToPos = false;

    function scrollToCurrentDay() {
        if (scrolledToPos) {
            return;
        }

        const currentDayElement = document.getElementById('current-day');
        if (currentDayElement) {
            currentDayElement.scrollIntoView({behavior: 'smooth', block: 'center'});
            scrolledToPos = true;
        }
    }

    function filterSchedule(schedule) {
        const subgroupNumber = String($subgroupFilter.subgroupNumber);

        for (let i = 0; i < schedule.length; i++) {
            for (let j = 0; j < schedule[i].events.length; j++) {
                const lesson = schedule[i].events[j];

                if (lesson.subGroup && !lesson.subGroup.endsWith(subgroupNumber)) {
                    switch ($subgroupFilter.filterType) {
                        case "exclude": {
                            schedule[i].events.splice(j, 1);
                            break;
                        }
                        case "translucent": {
                            schedule[i].events[j].wrongSubgroup = true;
                            break;
                        }
                    }
                }
            }        
        }
    }

    async function loadData() {
        loading = true

        startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay() + weekOffset * 7);

        endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + (6 - today.getDay()) + weekOffset * 7);

        try {
            const freshData = await apiGetSchedule(startOfWeek, endOfWeek);
            if ($subgroupFilter.filterEnabled)
                filterSchedule(freshData);

            scheduleCache.set(freshData);
            let discoveredSubgroupsSet = new Set(get(discoveredSubgroups))
            // TODO
            // freshData.forEach(e => e.events.forEach() discoveredSubgroupsSet.add(e.))
        } catch (e) {
            console.error('Error fetching fresh data:', e);
            error = e;
        }
        loading = false
        if ($preferences.preferAutoScrollToToday)
            setTimeout(scrollToCurrentDay, 300);
    }

    export function moveWeekOffset(offset) {
        weekOffset += offset;
        loadData();
    }

    loadData();

    let focusHandler;

    onMount(() => {
        focusHandler = () => {
            if (!isCurrentDay(today)) {
                today = new Date();
                loadData();
            }
        };
        window.addEventListener("focus", focusHandler);
    });

    onDestroy(() => {
        window.removeEventListener("focus", focusHandler);
    });
</script>

<style>
    .schedule-day {
        margin-block-start: 0rem;
        margin-block-end: 1rem;
    }
    article.schedule-day > div[wrongSubgroup=true] {
        opacity: 0.3;
    }
</style>

<Navbar loading={loading} pageName="{$_('page.schedule.nav', {default: 'Schedule'})}">
    <nav class="middle-align">
        <button class="circle transparent" on:click={() => moveWeekOffset(-1)}>
            <MIcon>arrow_back</MIcon>
        </button>
        <span>{startOfWeek.toLocaleDateString('ru-RU')} - {endOfWeek.toLocaleDateString('ru-RU')}</span>
        <button class="circle transparent" on:click={() => moveWeekOffset(1)}>
            <MIcon>arrow_forward</MIcon>
        </button>
    </nav>
    {#if $scheduleCache && $scheduleCache.length > 0}
        <div class="grid">
            {#each $scheduleCache as schedule}
                <article class="schedule-day round border s12 m6 l6" class:secondary-container={isCurrentDay(schedule.date)}
                         id={isCurrentDay(schedule.date) ? 'current-day' : ''}>
                    <h6 class="surface-variant center-align">{convertDateReadable(new Date(schedule.date))}</h6>
                    {#each schedule.events as event, index}
                        <div class="row" wrongSubgroup={event.wrongSubgroup}>
                            <h5 class="s small primary-text max" style="margin-top: 25px;">{event.description}</h5>
                            <h5 class="m l primary-text max" style="margin-top: 25px;">{event.description}</h5>
                            <i class="left-padding tiny-padding"
                               class:notranslate={true} translate="no">{iconMap[event.typeWorkName]}</i>
                        </div>
                        <div class="row top-align" style="margin-top: 10px;" wrongSubgroup={event.wrongSubgroup}>
                            <div>
                                <h5 class="s small">{convertTimeReadable(event.timeBegin)}</h5>
                                <h5 class="s small">{convertTimeReadable(event.timeEnd)}</h5>
                                <h6 class="m l">{convertTimeReadable(event.timeBegin)}</h6>
                                <h6 class="m l">{convertTimeReadable(event.timeEnd)}</h6>
                            </div>
                            <div class="max">
                                <h5 class="s small secondary-text ">{event.fio}</h5>
                                <h6 class="m l secondary-text">{event.fio}</h6>
                            </div>
                            <nav class="bottom-align vertical" style="padding-top: 0px">
                                <h5 class="s small">{event.room} ({event.building})</h5>
                                <h6 class="m l">{event.room} ({event.building})</h6>
                                {#if event.subGroup}
                                    <h6 class="small bold" style="margin: 0;">({event.subGroup})</h6>
                                {/if}
                            </nav>
                        </div>
                    {/each}
                </article>
            {/each}
        </div>
    {:else if $scheduleCache && $scheduleCache.length === 0}
    <div class="center-align" style="display: flex">
        <h6>{$_('page.schedule.empty', {default: 'There are no classes this week'})}</h6>
    </div>
    {/if}
    {#if error}
        <div class="row">
            <MIcon class="orange-text">warning</MIcon>
            <div class="max">{$_('error', {default: 'Error: '})}{error.message}</div>
        </div>
    {/if}
</Navbar>